export const question1 =
	"1. ¿Porqué debería implementar un Chatbot de IA?";

export const question2 =
	"2. ¿Cómo sabe el Chatbot de IA sobre de mi negocio?";

export const question3 = "3. ¿Qué son las automatizaciones y como me beneficiaría?";

export const question4 =
"4. ¿Cuánto se tarda en implementar MegaBot en mi página web?";

export const question5 = "5. ¿Cuánto sale una implementación de MegaBot?";